import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import VConsole from 'vconsole'
import {
  initWeb3Onboard,
} from './services'
import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
} from '@web3-onboard/react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import './bootstrap.min.css'
import './App.css'

import Header from './views/Header/Header.js'
import Footer from './views/Footer/Footer.js'

import artifactsOfTokenShop from './TokenShop.json'
import artifactsOfToken from './tokens/TESTERC20.json'

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

if (window.innerWidth < 700) {
  new VConsole()
}

let provider
let tokenShop
let signer

const App = () => {
  // const addressOfTokenShop = "0x5FbDB2315678afecb367f032d93F642f64180aa3"
  const addressOfTokenShop = "0x529a45Ed39FB14abA54058A2A5d53C6ce5C79724" // On Polygon zkEVM Testnet

  const tokensForSale = [
    // { chainId: 31337, symbol: "SKYBIT", address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0" },
    // { chainId: 1442, symbol: "SKYBIT", address: "0xB5aE4Ef3d55dF83b4F6Df56FfF4209a085c0393A" }, // On Polygon zkEVM Testnet
    { chainId: 1442, symbol: "SKYBIT", address: "0x793C76f69a6BBe65633B8B7764E38b5480775fFF" }, // New SKYBIT token On Polygon zkEVM Testnet
  ]

  const tokensForPayment = [
    // { chainId: 1, symbol: "USDT", address: "0xdAC17F958D2ee523a2206206994597C13D831ec7" },
    // { chainId: 1, symbol: "USDC", address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" },
    // { chainId: 137, symbol: "USDT", address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F" },
    // { chainId: 137, symbol: "USDC", address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359" },
    // { chainId: 137, symbol: "USDC.e", address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" },

    // { chainId: 31337, symbol: "TESTERC20", address: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9", decimals: 18, addressLive: "0x0" },
    // { chainId: 31337, symbol: "USDT", address: "0x0165878A594ca255338adfa4d48449f69242Eb8F", decimals: 6, addressLive: "0xdAC17F958D2ee523a2206206994597C13D831ec7" },
    // { chainId: 137, symbol: "USDC", address: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6", decimals: 6, addressLive: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359" },
    // { chainId: 137, symbol: "USDT", address: "0x0B306BF915C4d645ff596e518fAf3F9669b97016", decimals: 6, addressLive: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F" },

    { chainId: 1442, symbol: "USDC", address: "0xd55fdDf472A3f287F05EdDfCe151a72B95EC7fE5", decimals: 6 }, // On Polygon zkEVM Testnet

  ]


  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] = useConnectWallet()
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  const [notifications, customNotification, updateNotify] = useNotifications()
  const connectedWallets = useWallets()
  const updateAccountCenter = useAccountCenter()

  const [web3Onboard, setWeb3Onboard] = useState(null)
  // const [toChain, setToChain] = useState('0x7a69') // localhost
  const [toChain, setToChain] = useState(chains[0].id) //Polygon zkEVM Testnet: 1442 ('0x5a2')


  // const [accountCenterSize, setAccountCenterSize] = useState('normal')

  const [tokenForSale, setTokenForSale] = useState(tokensForSale[0])
  const [tokenForPayment, setTokenForPayment] = useState(tokensForPayment[0])

  const [tokenPricePerFTAsFT, setTokenPricePerFTAsFT] = useState()
  const [quantityToBuyInFT, setQuantityToBuyInFT] = useState(10000)
  const [balanceOfTokenForPaymentAsFT, setBalanceOfTokenForPaymentAsFT] = useState()
  const [balanceOfTokenForSaleAsFT, setBalanceOfTokenForSaleAsFT] = useState()
  const [statusMessage, setStatusMessage] = useState("Ready")


  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  useEffect(() => {
    // provider = wallet?.provider ? new ethers.providers.Web3Provider(wallet.provider, 'any') : ethers.getDefaultProvider(`http://localhost:8545`)
    // provider = wallet?.provider ? new ethers.providers.Web3Provider(wallet.provider, 'any') : new ethers.providers.Web3Provider(window.ethereum)
    if (wallet?.provider) {
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')
      signer = provider.getUncheckedSigner()
      tokenShop = new ethers.Contract(addressOfTokenShop, artifactsOfTokenShop.abi, signer)
    } else {
      provider = new ethers.providers.JsonRpcProvider(chains[0].rpcUrl)
      tokenShop = new ethers.Contract(addressOfTokenShop, artifactsOfTokenShop.abi, provider)
    }


  }, [wallet, chains])

  useEffect(() => {
    async function fetchData() {
      setTokenPricePerFTAsFT(ethers.utils.formatUnits(await tokenShop.tokenPrices(tokenForSale.address, tokenForPayment.address), tokenForPayment.decimals))
    }
    fetchData()
  }, [tokenForSale, tokenForPayment])

  useEffect(() => {
    async function fetchData() {
      if (wallet?.provider) {
        const artifacts = require(`./tokens/${tokenForPayment.chainId}_${tokenForPayment.symbol}.json`)
        const tokenForPaymentInstance = new ethers.Contract(tokenForPayment.address, artifacts.abi, signer ? signer : provider)
        const tokenForSaleInstance = new ethers.Contract(tokenForSale.address, artifactsOfToken.abi, signer ? signer : provider)

        setBalanceOfTokenForPaymentAsFT(ethers.utils.formatUnits(await tokenForPaymentInstance.balanceOf(wallet?.accounts[0]?.address), tokenForPayment.decimals))
        setBalanceOfTokenForSaleAsFT(ethers.utils.formatUnits(await tokenForSaleInstance.balanceOf(wallet?.accounts[0]?.address), tokenForSale.decimals))
      }
    }
    fetchData()
  }, [wallet, tokenForSale, tokenForPayment])


  const readyToTransact = async () => {
    if (!wallet) {
      const walletSelected = await connect()
      if (!walletSelected) return false
    }
    // prompt user to switch to Goerli for test
    await setChain({ chainId: toChain })

    return true
  }


  const buyTokens = async () => {
    const addressOfPaymentRecipient = "0x70997970C51812dc3A010C7d01b50e0d17dc79C8"

    try {
      const tokenPricePerFTAsSU = await tokenShop.tokenPrices(tokenForSale.address, tokenForPayment.address)
      console.log(`tokenPricePerFTAsSU: ${tokenPricePerFTAsSU}`)
      // console.log(`tokenPricePerFTAsFT: ${ethers.utils.formatUnits(tokenPricePerFTAsSU, tokenForPayment.decimals)}`)

      if (tokenPricePerFTAsSU > 0) {
        const artifacts = require(`./tokens/${tokenForPayment.chainId}_${tokenForPayment.symbol}.json`)
        const tokenForPaymentInstance = new ethers.Contract(tokenForPayment.address, artifacts.abi, signer)
        const tokenForSaleInstance = new ethers.Contract(tokenForSale.address, artifactsOfToken.abi, signer)
        const tokenForSaleInstanceDecimals = await tokenForSaleInstance.decimals()
        const quantityToBuyAsSU = ethers.utils.parseUnits(quantityToBuyInFT.toString(), tokenForSaleInstanceDecimals)
        console.log(`quantityToBuyAsSU: ${quantityToBuyAsSU}`)

        // const quantityOfTokenForPaymentAsSU = quantityToBuyAsSU.div(ethers.BigNumber.from(10).pow(tokenForSaleInstanceDecimals)).mul(tokenPricePerFTAsSU)
        const quantityOfTokenForPaymentAsSU = tokenPricePerFTAsSU.mul(quantityToBuyInFT)
        // const quantityOfTokenForPaymentAsSU = tokenPricePerFTAsSU * quantityToBuyInFT
        console.log(`quantityOfTokenForPaymentAsSU: ${quantityOfTokenForPaymentAsSU}`)
        console.log(`quantityOfTokenForPaymentAsFT: ${ethers.utils.formatUnits(quantityOfTokenForPaymentAsSU, tokenForPayment.decimals)}`)

        const addressOfPayer = wallet?.accounts[0]?.address

        let permitDeadline = 0, splitSig = { v: 0, r: ethers.utils.formatBytes32String(0), s: ethers.utils.formatBytes32String(0) }


        if ((await tokenForPaymentInstance.allowance(addressOfPayer, tokenShop.address)) < quantityOfTokenForPaymentAsSU) {
          if (tokenHasPermit(await provider.getCode(tokenForPayment.address))) {
            permitDeadline = Math.floor(Date.now() / 1000 + 3600) // 1h from now. 0 causes ERC2612ExpiredSignature.
            splitSig = await getPermitSignature(signer, tokenForPaymentInstance, addressOfPayer, tokenShop.address, quantityOfTokenForPaymentAsSU, permitDeadline)
          } else {
            console.log(`Permit function not found in token. Payer is calling token's approve function instead...`)
            const tx = await tokenForPaymentInstance.approve(tokenShop.address, quantityOfTokenForPaymentAsSU) // only owner can call approve to let spender spend his tokens.
            await tx.wait()
          }
        } else console.log(`allowance is already enough`)

        const printBalances = async () => {
          console.log(`user has ${ethers.utils.formatUnits(await provider.getBalance(addressOfPayer))} of native currency`)
          console.log(`user has ${ethers.utils.formatUnits(await tokenForPaymentInstance.balanceOf(addressOfPayer), tokenForPayment.decimals)} of TokenForPayment`)
          console.log(`user has ${ethers.utils.formatUnits(await tokenForSaleInstance.balanceOf(addressOfPayer))} of TokenForSale`)
          console.log(`Payment recipient has ${ethers.utils.formatUnits(await tokenForPaymentInstance.balanceOf(addressOfPaymentRecipient), tokenForPayment.decimals)} of TokenForPayment`)
          console.log(`Token shop has ${ethers.utils.formatUnits(await tokenForSaleInstance.balanceOf(tokenShop.address))} of TokenForSale`)
        }

        console.log(`Initially:`)
        printBalances()

        const buyTx = await tokenShop.buyTokens(
          tokenForSale.address,
          tokenForPayment.address,
          addressOfPayer,
          quantityToBuyAsSU,
          permitDeadline,
          // splitSig
          ...Object.values(splitSig)
        )
        await buyTx.wait()

        setBalanceOfTokenForPaymentAsFT(ethers.utils.formatUnits(await tokenForPaymentInstance.balanceOf(wallet?.accounts[0]?.address), tokenForPayment.decimals))
        setBalanceOfTokenForSaleAsFT(ethers.utils.formatUnits(await tokenForSaleInstance.balanceOf(wallet?.accounts[0]?.address), tokenForSale.decimals))

        console.log(`After:`)
        printBalances()

      } else setStatusMessage(`Token ${tokenForPayment.address} is not accepted. Use a different token.`)

    } catch (e) {
      console.error(e)
      setStatusMessage(Object.hasOwn(e, "message") ? e.message : e)
    }

  }

  const tokenHasPermit = bytecode => bytecode.includes(`d505accf`) && bytecode.includes(`7ecebe00`) // permit & nonces selectors

  const getPermitSignature = async (signer, tokenContract, addressOfOwner, addressOfSpender, value, deadline) => { // owner allows spender to spend value wei tokens before deadline
    const [name, nonce] = await Promise.all([
      tokenContract.name(),
      tokenContract.nonces(addressOfOwner),
    ])

    let version = `1`
    try {
      version = (await tokenContract.version()).toString()
    } catch (error) {
      if (error.data === "0x") console.log(error.data)
      else console.error(error)
    }
    console.log(`version: ${version}`)

    const domain = {
      name,
      version,
      chainId: provider._network.chainId,
      verifyingContract: tokenContract.address
    }

    const permitTypes = {
      Permit: [{
        name: `owner`,
        type: `address`
      },
      {
        name: `spender`,
        type: `address`
      },
      {
        name: `value`,
        type: `uint256`
      },
      {
        name: `nonce`,
        type: `uint256`
      },
      {
        name: `deadline`,
        type: `uint256`
      },
      ],
    }
    const permitValues = {
      owner: addressOfOwner,
      spender: addressOfSpender,
      value,
      nonce,
      deadline,
    }

    const signature = await signer._signTypedData(domain, permitTypes, permitValues) // owner signs the data
    // const { v, r, s } = ethers.Signature.from(signature)
    const { v, r, s } = ethers.utils.splitSignature(signature)

    // const recoveredAddress = verifyTypedData(
    //   domain,
    //   permitTypes,
    //   permitValues,
    //   { v, r, s }
    // )
    // console.log(`recoveredAddress: ${recoveredAddress}. Verification ${recoveredAddress === addressOfOwner ? "passed" : "failed"}.`)

    return { v, r, s }
  }


  if (!web3Onboard) return <div>Loading...</div>

  return (
    <Container className="p-5">
      <a
        className="bn-logo-link"
        href="https://SKYBIT.ASIA"
        target="_blank"
        rel="noopener noreferrer"
        title="SKYBIT"
      >
        <img className="bn-logo-demo mx-auto" src="https://i.imgur.com/6CK3QbF.png" alt="SKYBIT Logo" />
      </a>

      <Header
        connectedChain={wallet ? connectedChain : null}
        address={wallet?.accounts[0]?.address}
        balance={wallet?.accounts[0]?.balance}
        ens={wallet?.accounts[0]?.ens}
        connect={connect}
      />
      <section className="main">
        <div className="main-content">
          <div className="vertical-main-container">

            <h1>Buy SKYBIT</h1>
            <h6>On {chains.find(chain => chain.id === toChain).label}</h6>

            {!connectedChain && <Button className="mx-auto" variant="primary"
              onClick={async () => {
                const walletsConnected = await connect()
                console.log('connected wallets: ', walletsConnected)
              }}
            >
              Connect wallet
            </Button>}

            <div className="container notify">
              <Form>
                {/* <Form.Group as={Row}>
                  <Form.Label column xs={4}><b>Blockchain:</b></Form.Label>
                  <Col xs={8}>
                    <Form.Select
                      onChange={({ target: { value } }) => setToChain(value)}
                      value={toChain}
                    >
                      {chains.map(({ id, label }) => {
                        return (
                          <option value={id} key={id}>{label}</option>
                        )
                      })}
                    </Form.Select>
                  </Col>
                </Form.Group> */}

                <Form.Group as={Row}>
                  <Form.Label column xs={4}><b>Pay using:</b></Form.Label>
                  <Col xs={8}>
                    <div key={`inline-radio`} className="mb-3">
                      {tokensForPayment.filter(token => ethers.utils.hexValue(token.chainId) === toChain).map(({ symbol, address }) => (
                        // {tokensForPayment.map(({ symbol, address }) => (
                        <Form.Check
                          inline
                          key={address}
                          label={symbol}
                          name="TokenForPayment"
                          type="radio"
                          id={address}
                          value={address}
                          checked={tokenForPayment.address === address}
                          onChange={async ({ target: { value } }) => {
                            setTokenForPayment(tokensForPayment.find(token => token.address === value))
                          }}
                        />
                      ))}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column xs={4}><b>Price:</b></Form.Label>
                  {
                    tokenPricePerFTAsFT ?
                      <Form.Label column xs={8}>{tokenPricePerFTAsFT} {tokenForPayment.symbol} per {tokenForSale.symbol}</Form.Label> :
                      <Form.Label column xs={8}>Loading...</Form.Label>
                  }
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column xs={4}><b>Quantity you want:</b></Form.Label>
                  <Col xs={4}>
                    <Form.Control placeholder="quantity"
                      onChange={e => setQuantityToBuyInFT(Math.abs(e.target.value))}
                      value={quantityToBuyInFT} />
                  </Col>
                  <Form.Label column xs={4}>{tokenForSale.symbol}</Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column xs={4}><b>Total to pay:</b></Form.Label>
                  {tokenPricePerFTAsFT && <Form.Label column xs={8}>{tokenPricePerFTAsFT * quantityToBuyInFT} {tokenForPayment.symbol}</Form.Label>}
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  {connectedChain ?
                    <Button variant="primary"
                      onClick={async () => {
                        const ready = await readyToTransact()
                        if (!ready) return
                        setStatusMessage("Processing... take action in your wallet")
                        await buyTokens()
                        await updateAccountCenter()
                        setStatusMessage("Done!")
                      }}
                    >
                      Next
                    </Button>
                    :
                    <Button variant="primary"
                      onClick={async () => {
                        const walletsConnected = await connect()
                        console.log('connected wallets: ', walletsConnected)
                      }}
                    >
                      Connect wallet
                    </Button>
                  }

                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column xs={4}><b>Status:</b></Form.Label>
                  <Form.Label column xs={8}>{statusMessage}</Form.Label>
                </Form.Group>


                {connectedChain &&
                  <>
                    <hr />
                    {/* <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label column xs={4}><b>Your address:</b></Form.Label>
                      <Form.Label column xs={8}>{wallet?.accounts[0]?.address}</Form.Label>
                    </Form.Group> */}

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label column xs={4}><b>You have:</b></Form.Label>
                      <Form.Label column xs={8}>{balanceOfTokenForPaymentAsFT} {tokenForPayment.symbol}</Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label column xs={4}></Form.Label>
                      <Form.Label column xs={8}>{balanceOfTokenForSaleAsFT} {tokenForSale.symbol}</Form.Label>
                    </Form.Group>

                    {/* <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label column xs={4}></Form.Label>
                      <Form.Label column xs={8}>{Object.keys(wallet?.accounts[0]?.balance).map((key, i) => (
                        <div key={key}>
                          {wallet?.accounts[0]?.balance[key]} {key}
                        </div>
                      ))}</Form.Label>
                    </Form.Group> */}
                  </>
                }

              </Form>
            </div>
          </div>
        </div>
      </section >
      {/* <Footer /> */}
    </Container >
  )
}

export default App
