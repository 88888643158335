const networkEnum = Object.freeze({
  '0x1': 'Ethereum Main',
  '0x38': 'BSC',
  '0x89': 'Polygon',
  '0xfa': 'Fantom',
  '0x5a2': 'Polygon zkEVM Testnet',
  '0x7a69': 'localhost',
  localhost: 'localhost'
});
export default networkEnum;
